import React from "react";
import { useStaticQuery, graphql } from 'gatsby';
import CollapsingMenu from "../SideBar/CollapsingMenu";

const NewsMenu = () => {
  const data = useStaticQuery(graphql`
    query menuQuery {
      drupal {
        menuByName(name: "news-types") {
          links {
            label
            url {
              path
            }
          }
        }
      }
    }
  `);

  const menuItems = data?.drupal?.menuByName?.links.map(({ label, url: { path } }) => { return { label, url: path }});

  // don't show the menu if there are no items
  if (!menuItems || menuItems.length < 1) return null

  return (
    <CollapsingMenu
      heading="News types"
      links={menuItems}
    />
  );
};

export default NewsMenu;
