import * as React from 'react';
import { graphql } from 'gatsby';
import { Button, Row, Col } from 'react-bootstrap';

import { Heading, Icon, Image, LeftContainer, MainContainer, PageContainer, Video } from '../../common';
import NewsMenu from '../../components/NewsMenu/NewsMenu';
import ArticleLabel from '../../components/ArticleLabel/ArticleLabel';
import ConditionalWrapper from '../../components/Helpers/ConditionalWrapper';
import { styleVars } from '../../lib/helpers/style';
import { formatDateMMMDYYYY } from '../../lib/helpers/date';

import AuthoredAccordion from '../../components/Authored/Accordion/Accordion';
import AuthoredHeading from '../../components/Authored/Heading/Heading';
import AuthoredImage from '../../components/Authored/Image/Image';
import AuthoredVideo from '../../components/Authored/Video/Video';
import AuthoredLink from '../../components/Authored/Link/Link';
import AuthoredText from '../../components/Authored/Text/Text';
import AuthoredBlockquote from '../../components/Authored/Blockquote/Blockquote';
import AuthoredFactoid from '../../components/Authored/Factoid/Factoid';
import AuthoredDocument from '../../components/Authored/Document/Document';
import HorizontalRuler from '../../components/HorizontalRuler/HorizontalRuler';

const { mobileBreakpoint } = styleVars;

export const query = graphql`
  fragment postContent on Drupal_NodePost {
    title
    fieldPageTitle
    entityCreated
    fieldNewsType {
      entity {
        ... on Drupal_TaxonomyTermNewsTypes {
          name
        }
      }
    }
    fieldFeaturedMedia {
      entity {
        ...childImageField
      }
    }
    fieldFeaturedVideo {
      entity {
        ... on Drupal_MediaRemoteVideo {
          fieldMediaOembedVideo
        }
      }
    }
    fieldAuthoredSections {
      entity {
        entityId
        entityBundle
        ...authoredAccordion
        ...authoredHeading
        ...authoredImage
        ...authoredLink
        ...authoredText
        ...authoredVideo
        ...authoredBlockquote
        ...authoredFactoid
        ...authoredDocument
      }
    }
  }
  query($nid: String!, $pagePath: String!) {
    drupal {
      nodeById(id: $nid) {
        ...postContent
      }
    }
    sitePage(path: { eq: $pagePath }) {
      children {
        ...childFiles
      }
    }
  }
`;

const Post = ({ data, pageContext: { pagePath } }) => {
  const {
    drupal: { nodeById: node },
    sitePage: { children: files },
  } = data;
  const {
    fieldAuthoredSections,
    fieldFeaturedVideo,
    fieldNewsType,
    fieldPageTitle,
    fieldFeaturedMedia,
    entityCreated,
  } = node;
  const componentMapping = {
    // drupal machine name : component name
    accordion: AuthoredAccordion,
    heading: AuthoredHeading,
    image: AuthoredImage,
    link: AuthoredLink,
    text: AuthoredText,
    video: AuthoredVideo,
    blockquote: AuthoredBlockquote,
    factoid: AuthoredFactoid,
    document: AuthoredDocument,
    horizontal_rule: HorizontalRuler,
  };

  const print = () => {
    const content = document.getElementById('printable-page');
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <>
      <MainContainer row className={`pt-4 pt-${mobileBreakpoint}-9 pb-11`}>
        <LeftContainer className={`pt-${mobileBreakpoint}-2`}>
          <NewsMenu />
        </LeftContainer>

        <PageContainer className={`pt-8 pt-${styleVars.mobileBreakpoint}-0`}>
          <ConditionalWrapper
            condition={!!fieldFeaturedVideo || fieldFeaturedMedia}
            wrapper={(children) => <div className="rounded-top overflow-hidden mb-4">{children}</div>}
          >
            {!!fieldFeaturedVideo ? (
              <Video entity={fieldFeaturedVideo} />
            ) : (
              fieldFeaturedMedia && <Image entity={fieldFeaturedMedia.entity} files={files} />
            )}
          </ConditionalWrapper>

          <Row className="mb-8">
            <Col md={8} className="mb-4 mb-md-0">
              <ArticleLabel type={fieldNewsType.entity.name} />
            </Col>
            <Col className="text-muted d-flex flex-column align-items-md-end">
              {formatDateMMMDYYYY(entityCreated)}
              <Button
                variant="link"
                onClick={() => {
                  print();
                }}
                className="d-flex align-items-center text-muted p-0 mt-3"
              >
                <Icon name="printer" className="mr-3" />
                <span>Print</span>
              </Button>
            </Col>
          </Row>

          <div id="printable-page">
            <Heading as="h1" styledAs="h3" className="mb-4">
              {fieldPageTitle}
            </Heading>

            {fieldAuthoredSections.map(({ entity }) => {
              const { entityBundle, entityId } = entity;

              const Component = componentMapping[entityBundle];
              return Component ? <Component key={entityId} entity={entity} files={files} /> : null;
            })}
          </div>

          <iframe
            id="ifmcontentstoprint"
            style={{ height: '0px', width: '0px', position: 'absolute', visibility: 'hidden' }}
            title="print"
          ></iframe>
        </PageContainer>
      </MainContainer>
    </>
  );
};

export default Post;
