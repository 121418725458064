import React from "react";
import { Icon } from "../../common";

const ArticleLabel = ({ className = '', type }) => {
  const styleName = type.toLowerCase().replace(' ', '-');
  
  return (
    <div className={`${className} article-label article-label-${styleName} d-flex align-items-center`}>
      <span className="label-icon text-white mr-3" aria-hidden="true"><Icon name={styleName} /></span>
      <span className="label-text font-weight-bold text-uppercase">{type}</span>
    </div>
  )
};

export default ArticleLabel;
